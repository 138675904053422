import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useState, useEffect } from "react";
import Class from "../../assets/images/gallery/class.jpg";
import Class1 from "../../assets/images/gallery/class1.jpg";
import Class2 from "../../assets/images/gallery/class2.jpg";
import Class3 from "../../assets/images/gallery/class3.jpg";

const Data = [
  {
    imgUrl: Class,
  },
  {
    imgUrl: Class1,
  },
  {
    imgUrl: Class2,
  },
  {
    imgUrl: Class3,
  },
];
function SimpleSlider() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow next-arrow sm:block hidden`} // This hides the arrow by default and shows it on 'sm' screens and above
        style={{ ...style, display: "block" }} // Keep your existing inline styles
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow prev-arrow sm:block hidden`} // This hides the arrow by default and shows it on 'sm' screens and above
        style={{ ...style, display: "block" }} // Keep your existing inline styles
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: isSmallScreen ? <></> : <NextArrow />,
    prevArrow: isSmallScreen ? <></> : <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 2000,
    rtl: true, //
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="h-2/4 m-auto ">
      <Slider {...settings}>
        {Data.map((d) => (
          <div className="slide-animation relative ">
            <div className="h-[45vh] w-[100%] md:h-[70vh] md:w-[100%] flex justify-center items-center">
              <img
                className="w-full h-full object-cover"
                src={d.imgUrl}
                alt="img"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SimpleSlider;
