import React from "react";
import ItemsContainer from "./itemsContainer";
import SocialIcons from "./SocialIcons";
import { Icons } from "./Menus";
import Download from "../download";

const footer = () => {
  return (
    <footer className="bg-gradient-to-r from-primary via-secondary  to-[#813a67e5] text-white ">
      <div className="md:flex md:justify-between md:items-center sm:px-12 px-4 bg-gradient-to-r from-primary via-secondary  to-[#813a6728] py-7">
        <h1 className="lg:text-4xl text-3xl md:mb-0 mb-6 lg:leading-normal font-semibold md:w-2/5">
          Get in <span className="text-success">Touch..!</span>
        </h1>
        <Download />
      </div>
      <ItemsContainer />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 text-center pt-2 text-gray-400 text-sm pb-8">
        <span>&copy; 2024 apply. All rights reserved.</span>
        <span>Terms . Privacy Policy HORECA</span>
        <SocialIcons Icons={Icons} />
      </div>
    </footer>
  );
};

export default footer;
