import Lamp from "../assets/images/lamp.png";
import Table from "../assets/images/table.png";
import Pottery from "../assets/images/pottery.png";

const Data = [
  {
    img: Lamp,
    name: "CUSTOMIZATION SERVICES",
    main: "metal",
  },
  {
    img: Table,
    name: "DESIGN CONSULTATION",
    main: "metal",
  },
  {
    img: Pottery,
    name: "QUALITY ASSURED",
    main: "metal",
  },
];

const Ourservices = () => {
  return (
    <div className="flex flex-col md:flex-row gap-3 px-4 md:px-10 my-10 md:my-40">
      {Data.map((d, index) => (
        <div className="w-full md:w-auto">
          <div className="relative">
            <div className="">
              <img className="w-[100%] h-auto" src={d.img} alt={d.name} />
            </div>
            <div className="absolute top-[20%] md:top-[30%] left-4 md:left-[5%]">
              <h3 className="text-xl md:text-2xl font-bold text-gray-300">
                {" "}
                {/* Adjusted text size for small screens */}
                {d.name}
              </h3>
              <p className="text-lg md:text-xl font-md text-gray-300">
                {" "}
                {/* Adjusted text size for small screens */}
                Get in touch with our experts
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Ourservices;
