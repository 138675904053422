import React from "react";

import {
  FaLinkedin,
  FaInstagram,
  FaWhatsapp,
  FaFacebook,
} from "react-icons/fa";

const SocialIcons = ({ icons }) => {
  return (
    <div className="text-[#9a9da2]">
      <div className=" p-2 cursor-pointer inline-flex items-center rounded-full  mx-1.5 text-xl gap-x-3">
        <FaLinkedin className="hover:text-gray-100 hover:bh-teal-500 duration-300" />
        <a
          href="https://www.facebook.com/Horeca.ffe?mibextid=uzlsIk"
          target="blank"
        >
          <FaFacebook className="hover:text-gray-100 hover:bh-teal-500 duration-300" />
        </a>
        <a href="https://wa.me/+923495575850" target="blank">
          <FaWhatsapp className="hover:text-gray-100 hover:bh-teal-500 duration-300" />
        </a>
        <a
          href="https://www.instagram.com/horeca.ffe?igsh=ZDE1MWVjZGVmZQ%3D%3D"
          target="blank"
        >
          <FaInstagram className="hover:text-gray-100 hover:bh-teal-500 duration-300" />
        </a>
      </div>
    </div>
  );
};

export default SocialIcons;
