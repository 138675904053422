import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Lounge from "../assets/images/slider/lounge.jpg";
import Lobby from "../assets/images/slider/lobby.jpg";
import Restaurant from "../assets/images/slider/irest.jpg";
import Pool from "../assets/images/slider/pool.jpg";
import Beach from "../assets/images/slider/beach.jpg";
import Garden from "../assets/images/gallery/garden.jpg";
import Orestaurant from "../assets/images/slider/orest.jpg";
import Street from "../assets/images/slider/street.jpg";
import Office from "../assets/images/slider/office.jpg";
import Health from "../assets/images/gallery/health.jpg";
import Education from "../assets/images/slider/education.jpg";
import Room from "../assets/images/slider/room.jpg";
import React, { useState, useEffect } from "react";
import "../App.css";

const Data = [
  {
    imgUrl: Office,
    title: "Office Furniture",
    description:
      "Upgrade your office furniture with our customization expertise",
  },
  {
    imgUrl: Lounge,
    title: "Lounge Furniture",
    description:
      "Upgrade your lounge furniture with our customization expertise",
  },
  {
    imgUrl: Lobby,
    title: "Lobby Furniture",
    description:
      "Upgrade your lobby furniture with our customization expertise",
  },
  {
    imgUrl: Restaurant,
    title: "Restaurant Indoor",
    description:
      "Upgrade your restaurant furniture with our customization expertise",
  },
  {
    imgUrl: Pool,
    title: "Pool Furniture",
    description:
      "Upgrade your pool furniture with our customization expertise ",
  },
  {
    imgUrl: Beach,
    title: "Beach Furniture",
    description:
      "Upgrade your beach furniture with our customization expertise",
  },
  {
    imgUrl: Garden,
    title: "Garden Furniture",
    description:
      "Upgrade your garden furniture with our customization expertise",
  },
  {
    imgUrl: Orestaurant,
    title: "Restaurant Outdoor",
    description:
      "Upgrade your outdoor furniture with our customization expertise",
  },
  {
    imgUrl: Street,
    title: "Street Furniture",
    description:
      "Upgrade your street furniture with our customization expertise",
  },
  {
    imgUrl: Health,
    title: "Health Furniture",
    description:
      "Upgrade your health furniture with our customization expertise ",
  },
  {
    imgUrl: Education,
    title: "Education Furniture",
    description:
      "Upgrade your education furniture with our customization expertise ",
  },
  {
    imgUrl: Room,
    title: "Room Furniture",
    description:
      "Upgrade your room furniture with our customization expertise ",
  },
];

function SimpleSlider() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow next-arrow sm:block hidden`} // This hides the arrow by default and shows it on 'sm' screens and above
        style={{ ...style, display: "block" }} // Keep your existing inline styles
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow prev-arrow sm:block hidden`} // This hides the arrow by default and shows it on 'sm' screens and above
        style={{ ...style, display: "block" }} // Keep your existing inline styles
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: isSmallScreen ? <></> : <NextArrow />,
    prevArrow: isSmallScreen ? <></> : <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 4000,
    rtl: true, //
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="h-2/4 m-auto ">
      <Slider {...settings}>
        {Data.map((d) => (
          <div className="slide-animation relative ">
            <div className="h-[93vh] md:h-[100vh] flex justify-center items-center">
              <img
                className="w-full h-full object-cover"
                src={d.imgUrl}
                alt="img"
              />
            </div>

            {/* small screens slider */}

            <div className="absolute left-1/2 transform -translate-x-1/2 top-[40%] md:top-[50%] backdrop-blur-[2px] bg-opacity-40 w-[80%] md:w-[60%] sm:w-[70%] text-center lg:text-left bg-gray-100 rounded-xl md:hidden">
              <h1 className="uppercase mb-8 text-4xl lg:text-4xl font-md text-primary">
                {d.title}
              </h1>
              <h3 className="text-2xl lg:text-lg font-lg text-gray-700">
                {d.description}
              </h3>
            </div>

            {/* large screens slider */}
            <div className="lg:bg-opacity-50 backdrop-blur-[2px] lg:w-[17%] md:w-auto md:h-auto md:bg-opacity-50 absolute left-[5%] top-[70%] transform -translate-y-1/2 bg-gray-100 rounded-md lg:rounded-full overflow-hidden p-8 text-center lg:text-left hidden md:block ">
              <h1 className="uppercase mb-5 text-xl lg:text-[25px] font-semibold text-primary text-center ">
                {d.title}
              </h1>
              <h3 className="p-auto lg:text-xl font-semibold text-gray-700 text-center">
                {d.description}
              </h3>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SimpleSlider;
