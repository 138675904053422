import React from "react";
import Pslider1 from "../components/productsSlider/pslider1";
import Pslider2 from "../components/productsSlider/pslider2";
import Pslider3 from "../components/productsSlider/pslider3";
import Pslider4 from "../components/productsSlider/pslider4";
import Pslider5 from "../components/productsSlider/pslider5";
import Pslider6 from "../components/productsSlider/pslider6";
import Pslider7 from "../components/productsSlider/pslider7";
import Pslider8 from "../components/productsSlider/pslider8";
import Pslider9 from "../components/productsSlider/pslider9";
import Pslider10 from "../components/productsSlider/pslider10";
import Pslider11 from "../components/productsSlider/pslider11";
import Pslider12 from "../components/productsSlider/pslider12";

const Modal = ({ toggleModal, selectedIndex }) => {
  const handleClick = (e, index) => {
    e.stopPropagation();
    toggleModal(index); // Pass the index to toggleModal
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        onClick={handleClick}
        className="fixed md:fixed inset-0 bg-gray-800 opacity-50"
      ></div>
      <div className="rounded-lg mb-[40px] max-h-[100%] max-w-[95%] md:mb-[12%] md:max-h-[50%] md:max-w-[70%]">
        {/*props for slider*/}
        {selectedIndex === 0 ? (
          <Pslider1 />
        ) : selectedIndex === 1 ? (
          <Pslider2 />
        ) : selectedIndex === 2 ? (
          <Pslider3 />
        ) : selectedIndex === 3 ? (
          <Pslider4 />
        ) : selectedIndex === 4 ? (
          <Pslider5 />
        ) : selectedIndex === 5 ? (
          <Pslider6 />
        ) : selectedIndex === 6 ? (
          <Pslider7 />
        ) : selectedIndex === 7 ? (
          <Pslider8 />
        ) : selectedIndex === 8 ? (
          <Pslider9 />
        ) : selectedIndex === 9 ? (
          <Pslider10 />
        ) : selectedIndex === 10 ? (
          <Pslider11 />
        ) : selectedIndex === 11 ? (
          <Pslider12 />
        ) : null}
        <button
          className="absolute top-0 right-0 mt-1 mr-1 text-gray-500 hover:text-black"
          onClick={toggleModal}
        >
          CLOSE
        </button>
      </div>
    </div>
  );
};

export default Modal;
