export const CONTACT = [
  { name: "PHONE:", link: "#" },
  { name: "+92 347 9888699", link: "#" },
  { name: "WHATSAPP:", link: "#" },
  { name: "+92 349 5575850", link: "#" },
  { name: "EMAIL:", link: "#" },
  { name: "info@horecaffe.com", link: "#" },
];

export const PRODUCTS = [
  { name: "Room furniture", link: "#" },
  { name: "Lounge furniture", link: "#" },
  { name: "Lobby furniture", link: "#" },
  { name: "Auditorium Furniture", link: "#" },
  { name: "Office furniture", link: "#" },
  { name: "Restaurant indoor furniture", link: "#" },
];

export const OPRODUCTS = [
  { name: "Cafeteria Furniture", link: "#" },
  { name: "Pool furniture", link: "#" },
  { name: "Beach furniture", link: "#" },
  { name: "Garden furniture", link: "#" },
  { name: "Street furniture", link: "#" },
  { name: "Restaurant outdoor furniture", link: "#" },
];

export const OTPRODUCTS = [
  { name: "Shades", link: "#" },
  { name: "Decorations", link: "#" },
  { name: "Lightings", link: "#" },
  { name: "Pots & Planters", link: "#" },
  { name: "Linen", link: "#" },
  { name: "Reupholstery", link: "#" },
];

export const Icons = [
  { name: "FaLinkedin" }, // Using FaLinkedin from Font Awesome
  { name: "FaTwitter" }, // Using FaTwitter from Font Awesome
  { name: "FaFacebook" }, // Using FaGithub from Font Awesome
  { name: "FaWhatsapp" }, // This seems redundant, but I'll keep it consistent with the input data
  { name: "FaInstagram" }, // Using FaInstagram from Font Awesome
];
