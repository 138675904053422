import Header from "../components/header";
import SimpleSlider from "../components/slider";
import Ourservices from "../components/Ourservices";
import Intro from "../components/intro";
import Footer from "../components/footer/footer";
import OurOffers from "../components/ourOffers";
import Mainproducts from "../components/mainproducts";

const Homepage = () => {
  return (
    <div className="bg-gray-200 relative">
      <div className="absolute w-full z-30">
        <Header />
      </div>
      <SimpleSlider />
      <Ourservices />
      <Mainproducts />
      <Intro />
      <OurOffers />
      <Footer />
    </div>
  );
};
export default Homepage;
