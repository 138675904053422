import React, { useState } from "react";
import Modal from "react-modal";
import Videoimg from "../assets/images/videosection.jpg";
import { ChevronRightCircle } from "lucide-react";
import "../App.css";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "0",
    overflow: "hidden", // Prevents scroll bars on the modal
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const VideoModal = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  // Your Vimeo video URL
  const videoUrl = "https://player.vimeo.com/video/253542525?h=5be4dd4b30";

  const [iconColor, setIconColor] = useState("white");

  // Function to change icon color to pink
  const handleMouseEnter = () => setIconColor("#89355f");

  // Function to change icon color back to white
  const handleMouseLeave = () => setIconColor("white");

  return (
    <div className="relative">
      <img
        src={Videoimg}
        alt="Play Video"
        onClick={openModal}
        className="cursor-pointer w-[100%] h-[60vh] py-5 my-10x" // Tailwind classes for styling
      />
      <div
        src={Videoimg}
        onClick={openModal}
        className="absolute top-[40%] cursor-pointer right-[50%] "
      >
        {" "}
        <ChevronRightCircle
          size={100}
          color={iconColor}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="icon-transition"
        />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        style={customStyles}
      >
        <div className="relative">
          <iframe
            src={`${videoUrl}?autoplay=1`}
            width="1000"
            height="700"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            className="rounded "
            title="Vimeo Video"
          ></iframe>
          <button
            onClick={closeModal}
            className="absolute top-0 right-0  bg-transparent border-none cursor-pointer text-2xl p-4"
          >
            &times;
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default VideoModal;
