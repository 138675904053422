import React from "react";

const Services = () => {
  // Array of image data
  const imageData = [
    {
      id: 1,
      imgUrl:
        "https://images.unsplash.com/photo-1579196179453-1531eddf62e3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: " CRAFTSMANSHIP EXCELLENCE",
      description:
        "At Horeca, we believe in the artistry of furniture craftsmanship. Each piece we create is meticulously crafted by skilled artisans who take pride in their workmanship.",
    },
    {
      id: 2,
      imgUrl:
        "https://images.unsplash.com/photo-1709236205624-bc80835ad80e?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "TIMELESS DESIGN",
      description:
        "In terms of aesthetics and durability. From classic designs to modern interpretations, our furniture embodies enduring style that enriches any space.",
    },
    {
      id: 3,
      imgUrl:
        "https://images.unsplash.com/photo-1575839127405-ab6f4e312671?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "SUSTAINABLE PRACTICES",
      description:
        "By prioritizing sustainability, we aim to create furniture that not only enhances living spaces but also respects the planet.This is how we are",
    },
    {
      id: 4,
      imgUrl:
        "https://images.unsplash.com/photo-1519710164239-da123dc03ef4?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "FUNCTIONAL ELEGANCE",
      description:
        "elegance with practicality, ensuring that each piece serves its intended purpose while elevating the overall aesthetics of the space. ",
    },
    {
      id: 5,
      imgUrl:
        "https://images.unsplash.com/photo-1556742502-ec7c0e9f34b1?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "CUSTOMER APPROACH",
      description:
        "We are committed to providing exceptional customer service and fostering long-term relationships based on trust and transparency. ",
    },
    {
      id: 6,
      imgUrl:
        "https://images.unsplash.com/photo-1589561253831-b8421dd58261?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "INSPIRING SPICES",
      description:
        "We believe that well-designed furniture has the power to transform ordinary spaces into extraordinary environments that evoke emotion and inspire meaningful experiences. ",
    },
  ];

  return (
    <div className="text-center my-5">
      <h1 className="font-semibold text-4xl px-7 py-5 text-primary">
        OUR PHILOSOPHY
      </h1>
      <p className="md:text-xl font-[450] text-gray-500  px-7 pb-5">
        We Believe that furniture is more than just a practical necessity; it is
        an expression of artistry and a reflection of one’s personality and
        lifestyle.
      </p>
      <p className="md:text-xl font-[450] text-gray-500  px-7 pb-5 ">
        We Embrace the ethos of quality over quantity, each piece is crafted
        with precision and care.
      </p>
      <p className="md:text-xl font-[450] text-gray-500  px-7 pb-5 ">
        We value sustainability and strive to minimize our environmental impact
        by sourcing responsibly and designing for longevity
      </p>
      <p className="md:text-xl font-[450] text-gray-500  px-7 pb-5">
        Lets <span className="text-primary font-semibold">HORECA!</span>
      </p>

      <div className="flex min-h-screen items-center justify-center bg-white-100 mb-4">
        <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3">
          {imageData.map((image) => (
            <div
              key={image.id}
              className="group relative m-auto cursor-pointer rounded-md items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30 w-80 h-96"
            >
              <div className="h-full w-full">
                <img
                  className="h-full w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125"
                  src={image.imgUrl}
                  alt={image.title}
                />
              </div>
              <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
              <div className="absolute inset-0 flex translate-y-[60%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
                <h1 className="font-dmserif text-3xl text-white">
                  {image.title}
                </h1>
                <p className="mt-5 text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                  {image.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
