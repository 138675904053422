import Nav from "./nav";

function Header() {
  return (
    <header className=" bg-gray-300 bg-opacity-25 backdrop-blur-[5px] sticky top-0 z-20 ">
      <Nav />
    </header>
  );
}

export default Header;
