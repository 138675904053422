import React from "react";

function Offeryou() {
  return (
    <div className="text-center">
      <h3 className="my-5 text-primary  font-lg md:font-semibold text-3xl mx-5 ">
        OUR SERVICES
      </h3>
      <h1 className="text-4xl text-primary my-3 font-semibold mx-5 pb-5 ">
        WHAT WE CAN OFFER YOU
      </h1>
      <p className="md:text-xl font-[450] text-gray-500 pb-3  mx-5 ">
        Our team of experienced designers will collaborate with you to bring
        your vision to life,
      </p>
      <p className="md:text-xl font-[450] text-gray-500 pb-3  mx-5 ">
        crafting bespoke furniture pieces that reflect your style and
        personality. From concept to creation,
      </p>
      <p className="md:text-xl font-[450] text-gray-500 pb-4  mx-5 ">
        we ensure attention to detail and quality craftsmanship in every custom
        design project.
      </p>

      <div className="flex flex-wrap gap-y-5 justify-around mx-2 md:mx-0 my-5">
        <div className="flex flex-col bg-[#dddddd] w-full sm:w-[48%] lg:w-[30%] h-auto gap-y-5 shadow-md  rounded m-2">
          <div className="rounded-lg">
            <img
              className="w-full h-auto"
              src="https://images.unsplash.com/photo-1506599667882-385dd6673353?q=80&w=2073&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
          </div>

          <h1 className="text-center  text-3xl font-semibold bg-gradient-to-r from-secondary to-[#dddddd]">
            Maintenance and Repair
          </h1>
          <p className="text-center text-[20px] px-5 ">
            {" "}
            Our team of skilled technicians is equipped to handle everything
            from routine, ensuring that your furniture remains in pristine
            condition .
          </p>
          <button className="border-b-2 w-[50%] sm:w-[30%] border-solid mx-auto font-bold text-[18px] border-black hover:bg-gradient-to-r from-[#b5b5b5]">
            More Info
          </button>
        </div>

        <div className="flex flex-col bg-[#dddddd] w-full sm:w-[48%] lg:w-[30%] h-auto gap-y-5 shadow-md rounded-xl m-2">
          <div className="rounded-xl">
            <img
              className="w-full h-auto"
              src="https://images.unsplash.com/photo-1614018453562-77f6180ce036?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
          </div>

          <h1 className="text-center  text-3xl font-semibold bg-gradient-to-r from-secondary to-bg-[#dddddd]">
            Delivery & Installation
          </h1>
          <p className="text-center text-[20px] px-5 ">
            Sit back and relax while we take care of the logistics. Our
            comprehensive delivery and installation services ensure a
            hassle-free experience from start to finish.
          </p>
          <button className="border-b-2 w-[50%] sm:w-[30%] border-solid mx-auto font-bold text-[18px] border-black  hover:bg-gradient-to-r from-bg-[#dddddd] to-secondary">
            More Info
          </button>
        </div>

        <div className="flex flex-col bg-[#dddddd] w-full sm:w-[48%] lg:w-[30%] h-auto gap-y-5 shadow-md rounded-xl m-2">
          <div className="rounded-xl">
            <img
              className="w-full h-auto"
              src="https://images.unsplash.com/photo-1525182008055-f88b95ff7980?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
          </div>

          <h1 className="text-center  text-3xl font-semibold bg-gradient-to-r from-secondary to-bg-[#dddddd]">
            After-Sales Support
          </h1>
          <p className="text-center text-[20px] px-5 ">
            Our commitment to customer satisfaction extends beyond the sale.
            With our support, you can trust that we're always here for you.!
          </p>
          <button className="border-b-2 w-[50%] sm:w-[30%] border-solid mx-auto font-bold text-[18px] border-black hover:bg-gradient-to-r from-bg-[#dddddd] to-secondary">
            More Info
          </button>
        </div>
      </div>
    </div>
  );
}

export default Offeryou;
