import Header from "../components/header";
import OurOffers2 from "../components/ourOffer2";
import Footer from "../components/footer/footer";
import PSlider from "../components/pSlider";
import OurPhilosphy from "../components/ourPhilosphy";
import OurOffers from "../components/ourOffers";
import Mainproducts from "../components/mainproducts";

const Products = () => {
  return (
    <div className="bg-gray-200 relative  ">
      <div className="absolute w-full z-30">
        <Header />
      </div>
      <PSlider />
      <div className="flex justify-center">
        <div className="flex w-[100%]  h-[40px] md:w-[100%] md:max-w-none md:h-[60px] bg-gray-300 bg-opacity-25 backdrop-blur-[2px] items-center justify-center mt-[-60px] md:mt-[-75px]">
          <h1 className="text-xl md:text-3xl text-primary">PRODUCTS</h1>
        </div>
      </div>
      <div></div>
      <OurOffers2 />
      <OurPhilosphy />
      <Mainproducts />
      <OurOffers />
      <Footer />
    </div>
  );
};

export default Products;
