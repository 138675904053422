import Landing1 from "../components/landing2";
import Register from "../components/Register";
import Header from "../components/header";
import Offeryou from "../components/offeryou";
import Footer from "../components/footer/footer";

const Contactus = () => {
  return (
    <div className="bg-gray-200 relative ">
      <div className="absolute w-full z-30">
        <Header />
      </div>
      <Landing1 />
      <div className="flex justify-center">
        <div className="flex w-[100%]  h-[40px] md:w-[100%] md:max-w-none md:h-[60px] bg-gray-300 bg-opacity-25 backdrop-blur-[2px] items-center justify-center mt-[-60px] md:mt-[-75px]">
          <h1 className="text-xl md:text-3xl text-primary">CONTACT US</h1>
        </div>
      </div>
      <Register />
      <Offeryou />
      <Footer />
    </div>
  );
};

export default Contactus;
