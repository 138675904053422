import React from "react";
import "../App.css";
import Landing from "../assets/images/gallery/aboutus.jpg";

const landing = () => {
  return (
    <div className="h-2/4 m-auto">
      <div className="h-[73vh] md:h-[80vh] flex justify-center items-center slide-animation ">
        <img
          className="w-[100%] h-[100%] object-cover opacity-90"
          src={Landing}
          alt=""
        />
      </div>
    </div>
  );
};

export default landing;
