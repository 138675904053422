import Landing from "../components/landing";
import Header from "../components/header";
import VideoModal from "../components/videosection";
import Ourservices from "../components/Ourservices";
import Footer from "../components/footer/footer";
import Gallery from "../components/services";
import Intro2 from "../components/intro2";
import OurOffers2 from "../components/ourOffer2";

const AboutUs = () => {
  return (
    <div className="bg-gray-200 relative">
      <div className="absolute w-full z-30">
        <Header />
      </div>
      <Landing />
      <div className="flex justify-center">
        <div className="flex w-[100%]  h-[40px] md:w-[100%] md:max-w-none md:h-[60px] bg-gray-300 bg-opacity-25 backdrop-blur-[2px] items-center justify-center mt-[-60px] md:mt-[-75px]">
          <h1 className="text-xl md:text-3xl text-primary">ABOUT US</h1>
        </div>
      </div>
      <Intro2 />
      <OurOffers2 />
      <Gallery />
      <Ourservices />
      <VideoModal />
      <Footer />
    </div>
  );
};

export default AboutUs;
