import Whatsapp from "./assets/images/gallery/whatsapp.png";
import BackToTopButton from "./components/backtotop"; // Importing the BackToTopButton component

const SidebarLayout = ({ children }) => {
    return (
        <>
            <div className="relative">{children}</div>
            <div className="fixed top-[80%] md:top-[75%] right-5 md:right-10 hover:animate-bounce ">
                <div className="w-[70px] md:w-[90px] cursor-pointer h-[100px]">
                    <a href="https://wa.me/+923495575850" target="_blank">
                        <img src={Whatsapp} alt="" />
                    </a>
                </div>
            </div>
            {/* Adding the BackToTopButton component */}
            <BackToTopButton />
        </>
    );
};

export default SidebarLayout;
