import { Link } from "react-router-dom";

const Data = [
  {
    imgUrl:
      "https://images.unsplash.com/photo-1596522354195-e84ae3c98731?q=80&w=1787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Auditorium Furniture",
    description:
      "Whether you're outfitting a new house or a flat or upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1524758631624-e2822e304c36?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Cafeteria Furniture",
    description:
      "Whether you're outfitting a new ambience for your lounge upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1497366412874-3415097a27e7?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Partitions",
    description:
      "Whether you're outfitting a new design for your lobby upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1526745925052-dd824d27b9ab?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Show Case",
    description:
      "Whether you're outfitting a new hotel or restaurant or upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1540905255418-61f7468593cc?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Curtains",
    description:
      "Whether you're outfitting a new furniture for your pool side upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1515466678546-d0f851801e9c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Blinds",
    description:
      "Whether you're outfitting a portable and easy furniture upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1642887534384-81c81882dd1a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Shades",
    description:
      "Whether you're outfitting a new design & ambience for your garden upgrading your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1534349762230-e0cadf78f5da?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Decorations",
    description:
      "Whether you're outfitting a new hotel or restaurant or upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1602876153306-9a59170d9d8d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Lightings",
    description:
      "Whether you're outfitting something new for your road side upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1523575708161-ad0fc2a9b951?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Pots & Planters",
    description:
      "Whether you're outfitting a new office or working area upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1495195129352-aeb325a55b65?q=80&w=1776&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Linen",
    description:
      "Whether you're in need of patients bed or something comfortable upgrade your existing space ",
  },
  {
    imgUrl:
      "https://www.christies.com/-/jssmedia/images/features/articles/2018/12/05/guide-to-restoring-and-reupholstering-furniture/2-x-1-sofa.jpg?h=550&iar=0&w=880&rev=4e3abd5c50c74acc989775d065276443&hash=33a40046591eb1f5fb964769688ceed3cfce4a7d",
    title: "REUPHOLSTERY",
    description:
      "Whether you're outfitting a new school or university or upgrade your existing space ",
  },
];

const OurPhilosphy = () => {
  return (
    <div className=" py-10 text-center px-4 sm:px-6 lg:px-8 my-10">
      <h1 className="font-semibold text-3xl md:text-4xl px-7 pl-10 pb-5 text-primary">
        OTHER PRODUCTS
      </h1>
      <h3 className="md:text-xl font-[450] text-gray-500  px-7 pl-10 pb-10">
        Luxury hospitality solutions that deliver unforgettable guest
        experiences -tailored to <br /> your unique vision and executed to
        exceed your expectations.
      </h3>
      <div className="flex flex-wrap justify-center gap-5">
        {Data.map((d, index) => (
          <Link
            to={""}
            key={index}
            className="transition duration-300 hover:translate-y-[5px] max-w-sm w-full"
          >
            <div className="flex flex-col items-center">
              <div className="rounded-xl overflow-hidden">
                <img className="w-full h-auto" src={d.imgUrl} alt={d.name} />
              </div>
              <div className="w-full">
                <h3 className="uppercase text-xl bg-gray-200  text-center font-semibold">
                  {d.title}
                </h3>
                {/* <h3 className="text-xl font-semibold px-2 text-center">
                  {d.description}
                </h3> */}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default OurPhilosphy;
