import Item from "./Item";
import { CONTACT, PRODUCTS, OPRODUCTS, OTPRODUCTS } from "./Menus";

const itemsContainer = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:px-8 px-5 py-16 ">
      <Item Links={CONTACT} title="CONTACT" />
      <Item Links={PRODUCTS} title="INDOOR PRODUCTS" />
      <Item Links={OPRODUCTS} title="INDOOR PRODUCTS" />
      <Item Links={OTPRODUCTS} title="INDOOR PRODUCTS" />
    </div>
  );
};

export default itemsContainer;
