import React, { useState, useEffect } from "react";
import Logo1 from "../assets/images/fflogo1.png";
import { Link } from "react-router-dom";

const NavLink = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!document.getElementById("navbar").contains(e.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      window.addEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen]);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav id="navbar" className={isOpen ? "open" : "closed"}>
      <div className="flex flex-wrap items-center justify-between md:justify-around mx-auto p-8 ">
        <div className="logo w-[60px] h-[50px] md:w-[90px] md:h-[70px] cursor-pointer md:ml-[130px] md:mb-5 mt-[-26px] md:order-1">
          <Link to="/">
            <img src={Logo1} alt="" path="" />
          </Link>
        </div>
        <div className="flex">
          {/* Search button */}
          <button
            type="button"
            onClick={(toggleNavbar) => setShowSearch(!showSearch)}
            className="md:block dark:text-secondary rounded-lg text-md p-2.5 me-1"
          >
            {/* Search icon */}
          </button>
          {/* Search input */}
          <div className={`relative md:block ${showSearch ? "" : "hidden"}`}>
            {/* Search icon */}
            <input
              type="text"
              id="search-navbar"
              className="block w-[70%] ml-1 p-2 ps-10 text-xl rounded bg-transparent placeholder-primary "
              placeholder="Search..."
            />
          </div>
          {/* Navbar toggle button */}
          <button
            type="button"
            onClick={toggleNavbar}
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden focus:outline-none dark:text-secondary"
          >
            {/* Navbar toggle icon */}
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        {/* Navbar links */}
        <div
          className={`items-center justify-between w-full md:flex md:w-auto order-1 md:order-2 ${
            isOpen ? "flex" : "hidden"
          }`}
          id="navbar-search"
        >
          {/* Navbar links */}
          <ul className="flex flex-col p-4 md:p-0 mt-4 dark:text-black font-semibold text-[18px] rounded-md md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0">
            <li>
              <Link
                to="/"
                className="block py-2 px-3 rounded md:hover:bg-transparent text-primary md:hover:text-secondary md:p-0 md:dark:hover:bg-transparent transition-colors duration-300 relative"
                aria-current="page"
              >
                HOME
              </Link>
            </li>
            <li>
              <Link
                to="/products"
                className="block py-2 px-3 rounded md:hover:bg-transparent text-primary md:hover:text-secondary md:p-0 md:dark:hover:bg-transparent transition-colors duration-300 relative"
              >
                PRODUCTS
              </Link>
            </li>
            <li>
              <Link
                to="/aboutus"
                className="block py-2 px-3 rounded md:hover:bg-transparent text-primary md:hover:text-secondary md:p-0 md:dark:hover:bg-transparent transition-colors duration-300 relative"
              >
                ABOUT US
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="block py-2 px-3 rounded md:hover:bg-transparent text-primary md:hover:text-secondary md:p-0 md:dark:hover:bg-transparent transition-colors duration-300 relative"
              >
                CONTACT US
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavLink;
