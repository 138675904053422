import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const SendEmail = () => {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phonenumber, setNumber] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_oe49xen", "template_vag6hko", form.current, {
        publicKey: "Yr6fwDcfexHQEQp14",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          alert("email sent!. We will get in touch with you soon...");
          setName("");
          setEmail("");
          setAddress("");
          setNumber("");
          setMessage("");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <div className="flex w-full py-10 flex-col items-center">
        <p className="text-primary font-semibold whitespace-nowrap text-4xl sm:text-5xl sm:px-28 pb-4 sm:pb-8 self-center sm:self-start">
          Get in Touch.!
        </p>
        <div className="flex flex-col h-full w-5/6">
          <p className="text-secondary text-xl w-full pt-3 pb-2">Name</p>
          <input
            className="flex w-full rounded-md h-10 p-2 outline-none text-primary"
            placeholder="Enter your full name"
            type="text"
            name="from_name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <p className="text-secondary text-xl w-full pt-3 pb-2">Email</p>
          <input
            className="flex w-full rounded-md h-10 p-2 outline-none text-primary"
            placeholder="Enter your full email"
            type="email"
            name="from_email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <p className="text-secondary text-xl w-full pt-3 pb-2">Address</p>
          <input
            className="flex w-full rounded-md h-10 p-2 outline-none text-primary"
            placeholder="Enter your full address"
            type="text"
            name="from_address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <p className="text-secondary text-xl w-full pt-3 pb-2">
            Phone number
          </p>
          <input
            className="flex w-full rounded-md h-10 p-2 outline-none text-primary"
            placeholder="Enter your full name"
            type="number"
            name="from_number"
            value={phonenumber}
            onChange={(e) => setNumber(e.target.value)}
          />
          <p className="text-secondary text-xl w-full pt-3 pb-2">Message</p>
          <textarea
            rows="6"
            name="message"
            className="resize-none rounded-md w-full h-full overflow-hidden outline-none text-primary text-xl p-2"
            type="text"
            placeholder="Let us know what you need/Message"
            id="myTextarea"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <input
            type="submit"
            value="Send"
            className="bg-primary  transition duration-300 text-white rounded-md uppercase cursor-pointer text-center text-xl w-full mt-5 mb-2 py-2 hover:translate-y-[-4px] hover:bg-secondary"
          />
        </div>
      </div>
    </form>
  );
};

export default SendEmail;
