import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Homepage from "./pages/homepage";
import Products from "./pages/products";
import AboutUs from "./pages/aboutus";
import Contactus from "./pages/Contactus";
import SidebarLayout from "./SidebarLayout";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SidebarLayout><Homepage /></SidebarLayout>} />
        <Route path="/products" element={<SidebarLayout><Products /></SidebarLayout>} />
        <Route path="/aboutus" element={<SidebarLayout><AboutUs /></SidebarLayout>} />
        <Route path="/contact" element={<SidebarLayout><Contactus /></SidebarLayout>} />
      </Routes>
    </Router>
  );
};

export default App;
