import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MP1 from "../assets/images/gallery/bed1.jpg";
import MP2 from "../assets/images/gallery/lounge1.jpg";
import MP3 from "../assets/images/gallery/pool2.jpg";
import MP4 from "../assets/images/gallery/garden3.jpg";
import MP5 from "../assets/images/gallery/hotel2.jpg";
import MP6 from "../assets/images/gallery/lobby1.jpg";
import MP7 from "../assets/images/gallery/office1.jpg";
import MP8 from "../assets/images/gallery/beach.jpg";
import MP9 from "../assets/images/gallery/rest1.jpg";
import MP10 from "../assets/images/gallery/storage.jpg";
import MP11 from "../assets/images/gallery/street2.jpg";
import MP12 from "../assets/images/gallery/rug.jpg";
import MP13 from "../assets/images/gallery/hos3.jpg";
import MP14 from "../assets/images/gallery/class1.jpg";

const Data = [
  {
    img: MP1,
    title: "Room Decor",
    description:
      " Horeca, representing Hotels, Restaurants, and Catering, offers a premium touch to any space. From upscale furnishings of your room to lavish livingstyle gourmet cuisine, Horeca elements enhance ambiance and elevate guest experiences. ",
  },
  {
    img: MP2,
    title: "Lounge Decor",
    description:
      " Horeca, representing lounge elevations offers a premium touch to any space. From upscale furnishings to gourmet cuisine, Horeca elements enhance ambiance and elevate guest experiences.",
  },
  {
    img: MP3,
    title: "Pool Side Decor",
    description:
      "Horeca, representing pool side decors as well as Hotels, Restaurants, and Catering, offers a premium touch to any space. From upscale furnishings to gourmet cuisine, Horeca elements enhance ambiance and elevate guest experiences.",
  },
  {
    img: MP4,
    title: "Hanging Chair",
    description:
      "Horeca, representing hanging chairs for gardens and indoors Hotels, Restaurants, and Catering, offers a premium touch to any space. From upscale furnishings to gourmet cuisine, Horeca elements enhance ambiance and elevate guest experiences.",
  },
  {
    img: MP10,
    title: "indoor restaurant decor",
    description:
      "Elevate your space with signature storage solutions from HORECA. Discover bespoke cabinets, ingenious shelving units, and multifunctional ottomans designed to optimize space while adding a touch of sophistication to your home or business.",
  },
  {
    img: MP5,
    title: "Designer Illumination",
    description:
      "Illuminate your space with designer lighting fixtures that reflect the essence of HORECA. Explore statement chandeliers, elegant pendant lights, and stylish floor lamps to enhance ambiance and create focal points in every room or establishment.",
  },
  {
    img: MP11,
    title: "Luxurious Upholstered Comfort",
    description:
      "Indulge in luxurious upholstered seating options from HORECA. From sumptuous velvet sofas to leather armchairs and plush ottomans, our collection offers comfort and style to elevate your living spaces or hospitality areas.",
  },
  {
    img: MP9,
    title: "outdoor restaurant decor",
    description:
      "Experience the artistry of handcrafted wooden furniture from HORECA. Each piece, from dining tables to coffee tables and sideboards, exudes warmth and elegance with intricate detailing and superior craftsmanship, perfect for hotels, restaurants, cafes, and more.",
  },
  {
    img: MP12,
    title: "Artisanal Textiles and Rugs",
    description:
      " Infuse your space with color, pattern, and texture with artisanal textiles and rugs from HORECA. Explore handwoven rugs, embroidered throw pillows, and silk drapes to elevate your interior décor with luxury and style, ideal for hospitality settings seeking to create a welcoming atmosphere.",
  },
  {
    img: MP6,
    title: "Lobby Decor",
    description:
      "Make a statement with sculptural accent pieces from HORECA. Discover decorative vases, ceramic sculptures, and metal figurines that add visual interest and personality to your space, becoming conversation starters and focal points in restaurants, hotels, and cafes.",
  },
  {
    img: MP7,
    title: "luxurious office decor",
    description:
      "Add character and charm to your space with vintage and antique furniture finds from HORECA. Explore mid-century modern dressers, retro lounge chairs, and antique side tables to curate a timeless and eclectic look that resonates with the heritage of your establishment.",
  },
  {
    img: MP8,
    title: "Seamless Indoor-Outdoor Living",
    description:
      "Bring the outdoors in with stylish outdoor furniture designed for indoor use, available at HORECA. From rattan chairs to teak benches and weather-resistant dining sets, create a seamless transition between your indoor and outdoor hospitality areas, enhancing the guest experience.",
  },
  {
    img: MP13,
    title: "modernize your hospitals",
    description:
      "Bring the outdoors in with stylish outdoor furniture designed for indoor use, available at HORECA. From rattan chairs to teak benches and weather-resistant dining sets, create a seamless transition between your indoor and outdoor hospitality areas, enhancing the guest experience.",
  },
  {
    img: MP14,
    title: "decor your educational institutes",
    description:
      "Bring the outdoors in with stylish outdoor furniture designed for indoor use, available at HORECA. From rattan chairs to teak benches and weather-resistant dining sets, create a seamless transition between your indoor and outdoor hospitality areas, enhancing the guest experience.",
  },
];

const Mainproducts = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    rtl: true, //
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="text-center px-4 pb-5 mt-10">
      <h1 className="font-semibold text-4xl pb-10  text-primary">
        ELEVATE YOUR SPACE
      </h1>
      <p className="md:text-xl font-[450] text-gray-500  px-5  pb-20">
        Crafted with precision and passion, our curated collection of seating,
        tables, bedroom, living room,and outdoor <br /> furniture is designed to
        elevate the ambiance of{" "}
        <span className="text-xl font-bold text-primary">HO</span>tel -{" "}
        <span className="text-xl font-bold text-primary">RE</span>staurant-{" "}
        <span className="text-xl font-bold text-primary">CA</span>fes worldwide.
      </p>
      <div className="mb-[93px] overflow-hidden">
        <Slider {...settings} className="">
          {Data?.map((image, index) => (
            <div
              key={index}
              className="group relative cursor-pointer  items-center justify-center overflow-hidden  border-x-[20px] md:border-right-[22px] "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="h-80 w-69 ">
                <img
                  className="h-full w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-150 rounded"
                  src={image.img}
                  alt={image.title}
                />
              </div>
              <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black"></div>
              <div className="absolute inset-0 flex flex-col items-center justify-end px-9 text-center transition-all duration-500 group-hover:translate-y-0 ">
                <h1 className="uppercase font-dmserif text-lg md:text-3xl font-md text-white">
                  {image.title}
                </h1>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Mainproducts;
