import React from "react";

const download = () => {
  return (
    <div>
      <a download="" href="/files/HORECAPROFILE.pdf" target="blank">
        <span class="relative inline-flex items-center justify-center px-6 py-3 text-lg font-bold text-white transition-all duration-200 bg-gray-600 font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-700 hover:duration-300 ">
          <span>
            <em>G</em>
            <em>E</em>
            <em>T</em>
            <em> </em>
            <em>P</em>
            <em>R</em>
            <em>O</em>
            <em>F</em>
            <em>I</em>
            <em>L</em>
            <em>E</em>
          </span>
        </span>
      </a>
    </div>
  );
};

export default download;
