import React from "react";
import Logo1 from "../assets/images/fflogo1.png";
const intro = () => {
  return (
    <div className="flex justify-center mt-10">
      <div>
        <img className="w-[140px] md:w-[10rem]  m-auto" src={Logo1} alt="" />
        <div className="text-center my-10">
          <p className="md:text-xl font-[450] text-gray-500  px-6  pb-5 ">
            Welcome to the{" "}
            <span className="text-xl font-bold text-primary">HORECA</span>{" "}
            world, where we turn your furniture dreams into reality! With our
            expert craftsmanship and attention to detail, we specialize in
            creating
          </p>
          <p className="md:text-xl font-[450] text-gray-500  px-6  pb-5">
            custom furniture pieces tailored to your unique style and needs.
            From exquisite handcrafted designs to innovative solutions, our team
            of skilled artisans is dedicated
          </p>
          <p className="md:text-xl font-[450] text-gray-500  px-6  pb-5">
            to transforming your space into a reflection of your personality and
            vision. Experience the difference of personalized furniture
            solutions with{" "}
            <span className="text-xl font-bold text-primary">HORECA</span>.
          </p>
          <p className="md:text-lg font-[450] text-gray-500  px-6  pb-20">
            Let us elevate your{" "}
            <span className="text-xl font-bold text-primary">HO</span>TEL -{" "}
            <span className="text-xl font-bold text-primary">RE</span>STAURANT-{" "}
            <span className="text-xl font-bold text-primary">CA</span>FE to new
            heights of elegance and functionality.
          </p>
        </div>
      </div>
    </div>
  );
};

export default intro;
