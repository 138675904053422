import React, { useState } from "react";
import Modal from "../components/modal";

const imageData = [
  {
    imgUrl:
      "https://images.unsplash.com/photo-1522771739844-6a9f6d5f14af?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "ROOM FURNITURE",
    description:
      "Whether you're outfitting a new house or a flat or upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1489171078254-c3365d6e359f?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "LOUNGE FURNITURE",
    description:
      "Whether you're outfitting a new ambience for your lounge upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1621293954908-907159247fc8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "LOBBY FURNITURE",
    description:
      "Whether you're outfitting a new design for your lobby upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1555396273-367ea4eb4db5?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "RESTAURANT FURNITURE",
    description:
      "Whether you're outfitting a new hotel or restaurant or upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1640303458653-f67c6eea8f80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "POOL FURNITURE",
    description:
      "Whether you're outfitting a new furniture for your pool side upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1618245318763-a15156d6b23c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "BEACH FURNITURE",
    description:
      "Whether you're outfitting a portable and easy furniture upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1653581494409-270dfea9f0b0?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "GARDEN FURNITURE",
    description:
      "Whether you're outfitting a new design & ambience for your garden upgrading your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1559339352-11d035aa65de?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "RESTAURANT FURNITURE",
    description:
      "Whether you're outfitting a new hotel or restaurant or upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1605205187194-b335378e3697?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "STREET FURNITURE",
    description:
      "Whether you're outfitting something new for your road side upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1604328698692-f76ea9498e76?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "OFFICE FURNITURE",
    description:
      "Whether you're outfitting a new office or working area upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1512678080530-7760d81faba6?q=80&w=2074&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "HEALTH FURNITURE",
    description:
      "Whether you're in need of patients bed or something comfortable upgrade your existing space ",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1580582932707-520aed937b7b?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "EDUCATION FURNITURE",
    description:
      "Whether you're outfitting a new school or university or upgrade your existing space ",
  },
];

const OurOffers = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);

  const toggleModal = (index) => {
    setSelectedCardIndex(index);
    setShowModal(true); // Set showModal to true when opening the modal
  };

  const isSmallScreen = window.innerWidth < 640;

  return (
    <div className="text-center px-4 pb-5 mt-[-5px] md:mt-10 ">
      <h1 className="font-semibold text-3xl md:text-4xl pb-5 text-primary">
        OUR MAIN PRODUCTS
      </h1>
      <p className="text-lg md:text-xl font-[450] text-gray-500  px-5  pb-10">
        At <span className="text-xl font-bold text-primary">HORECA</span> we
        take pride in offering a diverse range of premium quality furniture
        products tailored to suit every taste & lifestyle. From Luxurious sofas
        and cozy armchairs to elegant dining sets and versatile storage
        solutions, our main furniture products encompass a wide variety of
        styles, materials, and finishes to suit any aesthetic preference.
      </p>
      <div className="flex items-center justify-center bg-white-100 mb-4">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {imageData.map((image, index) => (
            <div
              key={index}
              className="group relative m-auto cursor-pointer rounded-lg items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30"
              onClick={isSmallScreen ? null : () => toggleModal(index)}
            >
              <div className="h-96 w-72 ">
                <img
                  className="h-96 w-72 object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125"
                  src={image.imgUrl}
                  alt={image.title}
                />
              </div>
              <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
              <div className="absolute pb-5 inset-0 flex translate-y-[60%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
                <h1 className="font-dmserif text-3xl font-md text-white">
                  {image.title}
                </h1>
                <p className="mb-3 text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                  {image.description}
                </p>
                <button className="rounded-full bg-neutral-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60">
                  See More
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {showModal && (
        <Modal
          toggleModal={() => setShowModal(false)}
          selectedIndex={selectedCardIndex}
        />
      )}
    </div>
  );
};

export default OurOffers;
