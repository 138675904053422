import React from "react";
import Logo1 from "../assets/images/fflogo1.png";
const intro = () => {
  return (
    <div className="flex justify-center mt-10 md:mt-[175px]">
      <div className="">
        <img className="w-[120px] md:w-[10rem]  m-auto" src={Logo1} alt="" />
        <div className="text-center my-10">
          <p className="md:text-xl font-[450] text-gray-500  px-7 pl-10 pb-5 ">
            At <span className="text-xl font-bold text-primary">HORECA</span> we
            are passionate about crafting exceptional furniture solutions for
            the HORECA industry (Hotels, Restaurants, and Café/Catering). With
            years of expertise in the field, we specialize in providing
            high-quality, bespoke furniture tailored to the unique needs of our
            clients..
          </p>
          <p className="md:text-xl font-[450] text-gray-500  px-7 pl-10 pb-5">
            Our team of skilled craftsmen combines traditional techniques with
            innovative design to create furniture pieces that not only elevate
            spaces but also enhance the overall experience for Customers
          </p>
          <p className="md:text-xl font-[450] text-gray-500  px-7 pl-10 pb-5">
            Whether it's creating comfortable seating arrangements, functional
            storage solutions, or eye-catching décor accents, we pride ourselves
            on delivering excellence in every detail.
          </p>
          <p className="md:text-xl font-[450] text-gray-500  px-7 pl-10 pb-5">
            Driven by a commitment to quality, craftsmanship, and customer
            satisfaction, we work closely with our clients to understand their
            vision and bring it to life.
          </p>
          <p className="md:text-xl font-[450] text-gray-500  px-7 pl-10 pb-5">
            From concept to completion, we strive to exceed expectations and
            transform spaces into inviting, memorable environments.
          </p>
          <p className="md:text-xl font-[450] text-gray-500  px-7 pl-10 pb-5">
            Discover the difference that exceptional furniture can make for your
            establishment with{" "}
            <span className="text-xl font-bold text-primary">HORECA</span>. Let
            us be your partner in creating spaces that inspire, impress, and
            endure.
          </p>
          <p className="md:text-xl font-[450] text-gray-500  px-7 pl-10 pb-5">
            Feel free to customize it further to fit your brand's tone and
            style!
          </p>
        </div>
      </div>
    </div>
  );
};

export default intro;
